import icon1 from 'assets/img/icons/home.svg';
import icon2 from 'assets/img/icons/data.svg';
import transactions from 'assets/img/icons/transactions.svg';
import icon3 from 'assets/img/icons/lightning.svg';
import icon4 from 'assets/img/icons/veNFT.svg';
import icon1Light from 'assets/img/icons/home_light.svg';
import icon2Light from 'assets/img/icons/data_light.svg';
import icon3Light from 'assets/img/icons/transactions_light.svg';
import icon4Light from 'assets/img/icons/lightning_light.svg';
import p2p from 'assets/img/icons/p2p.svg';

const navList = [
  {
    path: '/dashboard',
    id: 'Dashboard',
    iconType: 'home',
    theme: 'filled',
    img: icon1,
    lightImg: icon1,
  },
  // {
  //   path: '/market',
  //   id: 'Market Data',
  //   iconType: 'area-chart',
  //   theme: '',
  //   img: icon2,
  //   lightImg: icon2,
  // },
  // {
  //   path: '/transaction',
  //   id: 'Transactions',
  //   iconType: 'area-chart',
  //   theme: '',
  //   img: transactions,
  //   lightImg: transactions,
  // },
  {
    path: '/p2p',
    id: 'P2P',
    iconType: 'area-chart',
    theme: '',
    img: p2p,
    lightImg: p2p,
  },
  // {
  //   path: '/vePools',
  //   id: 'veNFT',
  //   iconType: 'area-chart',
  //   theme: '',
  //   img: icon4,
  //   lightImg: icon4,
  // },
  // {
  //   path: '/liquidation',
  //   id: 'Liquidation',
  //   iconType: 'area-chart',
  //   theme: '',
  //   img: icon4,
  //   lightImg: icon4,
  // },
];

const customNavList = [
  {
    path: '/bridge',
    id: 'Bridge',
    iconType: 'area-chart',
    theme: '',
    img: icon3,
    lightImg: icon3,
  },
  {
    path: '/veNFT',
    id: 'veNFT',
    iconType: 'area-chart',
    theme: '',
    img: icon4,
    lightImg: icon4,
  },
];

export { navList, customNavList };
