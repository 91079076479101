import React from 'react';
import PropTypes from 'prop-types';
import loadingImg from 'assets/img/loading.svg';
import clockImg from 'assets/img/icons/clock.svg';
import permissionedImg from 'assets/img/icons/permissioned.svg';
import permissionlessImg from 'assets/img/icons/permissionless.svg';

import { P2PItemWrapper } from './style';

function P2PItem({ size }) {
  return (
    <P2PItemWrapper>
      <img className="avatar" src="" alt="" />
      <div className="info">
        <div className="info__title">Ecorisma - cUSD</div>
        <div className="info__permissioned">
          <img src={permissionedImg} alt="" />
          Permissioned Pool
        </div>
        {/* <div className="info__permissionless">
          <img src={permissionlessImg} alt="" />
          Permissionless Pool
        </div> */}
      </div>
    </P2PItemWrapper>
  );
}

export default P2PItem;
