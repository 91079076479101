import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { Progress, Form } from 'antd';
import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import { bindActionCreators } from 'redux';
import { connectAccount, accountActionCreators } from 'modules';
import BigNumber from 'bignumber.js';
import { getCTokenContract, methods } from 'utilities/contractService';
import { CONTRACT_CTOKEN_ADDRESS } from 'constants/address';
import { SAFE_MAX_THRESHOLD } from 'constants/enum';
import arrowRightImg from 'assets/img/arrow-right.png';
import coinImg from 'assets/img/chee-64.png';
import caiImg from 'assets/img/coins/cai.png';
import { TabContentWrapper, TabContent, LoadingBgWrapper } from 'components/common/style';
import { formatNumber, getBigNumber } from 'utilities/number';
import LoadingSpinner from 'components/common/loadingSpinner';

const abortController = new AbortController();

function BorrowTab({ asset, settings, ui, onCancel, setSetting }) {
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState({ value: getBigNumber() });
  const [borrowBalance, setBorrowBalance] = useState(getBigNumber(0));
  const [borrowPercent, setBorrowPercent] = useState(getBigNumber(0));
  const [newBorrowBalance, setNewBorrowBalance] = useState(getBigNumber(0));
  const [newBorrowPercent, setNewBorrowPercent] = useState(getBigNumber(0));
  const [assetName, setAssetName] = useState('');
  const [borrowBalanceIsZero, setBorrowBalanceIsZero] = useState(false);

  const updateInfo = useCallback(() => {
    const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
    const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
    const tokenPrice = getBigNumber(asset.tokenPrice);
    if (amount.value.isZero() || amount.value.isNaN()) {
      setBorrowBalance(totalBorrowBalance);
      if (totalBorrowLimit.isZero()) {
        setBorrowPercent(getBigNumber(0));
        setNewBorrowPercent(getBigNumber(0));
      } else {
        setBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
        setNewBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
      }
    } else {
      const temp = totalBorrowBalance.plus(amount.value.times(tokenPrice));
      setBorrowBalance(totalBorrowBalance);
      setNewBorrowBalance(temp);
      if (totalBorrowLimit.isZero()) {
        setBorrowPercent(getBigNumber(0));
        setNewBorrowPercent(getBigNumber(0));
      } else {
        setBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
        setNewBorrowPercent(temp.div(totalBorrowLimit).times(100));
      }
    }
  }, [settings.selectedAddress, amount, asset]);

  /**
   * Get Allowed amount
   */
  useEffect(() => {
    if (asset.ctokenAddress && settings.selectedAddress) {
      updateInfo();
    }
    return function cleanup() {
      abortController.abort();
    };
  }, [settings.selectedAddress, updateInfo, asset]);

  useEffect(() => {
    const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
    const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
    const total_new = BigNumber.maximum(totalBorrowLimit.minus(totalBorrowBalance), 0);
    setBorrowBalanceIsZero(total_new.isZero());
  }, [settings.totalBorrowLimit]);

  const validateAmount = (value) => {
    if (value === '0') {
      return {
        validateStatus: 'error',
        errorMsg: 'Amount is required',
      };
    }
    const temp = getBigNumber(value || 0);
    const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
    const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
    const isNotAllowed =
      temp.isGreaterThan(asset.liquidity.div(asset.tokenPrice)) ||
      newBorrowPercent.isGreaterThan(100) ||
      (!getBigNumber(asset.borrowCaps || 0).isZero() && temp.plus(asset.totalBorrows).isGreaterThan(asset.borrowCaps));
    if (isNotAllowed) {
      return {
        validateStatus: 'error',
        errorMsg: 'Input amount too large',
      };
    }
    return {
      validateStatus: 'success',
      errorMsg: null,
    };
  };
  const onAmountInput = ({ value }) => {
    setAmount({ ...validateAmount(value), value: getBigNumber(value) });
  };
  /**
   * Borrow
   */
  const handleBorrow = () => {
    const cTokenContract = getCTokenContract(asset.id, CONTRACT_CTOKEN_ADDRESS[settings.networkName]);
    if (asset && settings.selectedAddress) {
      setIsLoading(true);
      setSetting({
        pendingInfo: {
          type: 'Borrow',
          status: true,
          amount: formatNumber(amount, false, 8),
          symbol: asset.symbol,
        },
      });
      methods
        .send(
          cTokenContract.methods.borrow,
          [amount.value.times(getBigNumber(10).pow(settings.decimals[asset.id]?.token)).integerValue().toString(10)],
          settings.selectedAddress
        )
        .then(() => {
          setAmount({ value: getBigNumber(0) });
          setIsLoading(false);
          setSetting({
            pendingInfo: {
              type: '',
              status: false,
              amount: 0,
              symbol: '',
            },
          });
          onCancel();
        })
        .catch(() => {
          setIsLoading(false);
          setSetting({
            pendingInfo: {
              type: '',
              status: false,
              amount: 0,
              symbol: '',
            },
          });
        });
    }
  };
  /**
   * Max amount
   */
  const handleSetAmountByProportion = (proportion) => {
    const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
    const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
    const tokenPrice = getBigNumber(asset.tokenPrice);
    const safeMax = BigNumber.maximum(
      totalBorrowLimit.times(SAFE_MAX_THRESHOLD).minus(totalBorrowBalance),
      getBigNumber(0)
    );
    setAmount({ value: BigNumber.minimum(safeMax, asset.liquidity).div(tokenPrice).multipliedBy(proportion) });
  };
  if (assetName !== asset.displayName) {
    setAmount({ value: getBigNumber(0) });
    setAssetName(asset.displayName);
  }

  return (
    <TabContentWrapper>
      <Form name="basic" labelCol={{ span: 0 }} wrapperCol={{ span: 24 }} autoComplete="off">
        {isLoading && (
          <LoadingBgWrapper>
            <LoadingSpinner />
          </LoadingBgWrapper>
        )}
        <TabContent blurBg={borrowBalanceIsZero || isLoading} className="flex flex-column align-center just-content">
          <div className="tabcontent-title align-center">
            <img className="asset-img" src={asset.img} alt="asset" />
            <span>Borrow Amount</span>
          </div>
          <Form.Item
            name="amount"
            rules={[{ required: true, message: 'Please input amount!' }]}
            validateStatus={amount.validateStatus}
            help={amount.errorMsg}
          >
            <div className="flex flex-column align-center just-center input-wrapper">
              <NumberFormat
                autoFocus
                value={amount.value.isZero() ? null : amount.value.toString(10)}
                onValueChange={onAmountInput}
                // isAllowed={({ value }) => {
                //   const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
                //   const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
                //   return getBigNumber(value || 0)
                //     .plus(totalBorrowBalance)
                //     .isLessThanOrEqualTo(totalBorrowLimit);
                // }}
                thousandSeparator
                allowNegative={false}
                placeholder="0"
              />
              <span className="coin-name">{asset.displayName}</span>
            </div>
          </Form.Item>
          <div className="flex align-center balance-content">
            <span className="wallet-balance">
              {/* Wallet Balance */}
              <FormattedMessage id="Wallet Balance" />: {formatNumber(asset.walletBalance, true, 5)} {asset.symbol}
            </span>
            <div className="flex align-center">
              <span className="proportion-wrapper pointer">
                {[
                  // { label: '10%', val: 0.1 },
                  // { label: '25%', val: 0.25 },
                  { label: 'SAFE MAX', val: SAFE_MAX_THRESHOLD },
                ].map((proportion) => (
                  <span
                    key={proportion.label}
                    onClick={() => {
                      handleSetAmountByProportion(proportion.val);
                    }}
                  >
                    {proportion.label}
                  </span>
                ))}
              </span>
            </div>
          </div>
          <div className="flex flex-column just-center align-center apy-content">
            <div className="borrow-limit">
              <span>
                <FormattedMessage id="Borrowed" />
              </span>
              {amount.value.isZero() || amount.value.isNaN() ? (
                <span>{formatNumber(asset.borrowBalance)}</span>
              ) : (
                <div className="flex align-center just-between">
                  <span>{formatNumber(asset.borrowBalance)}</span>
                  <img className="arrow-right-img" src={arrowRightImg} alt="arrow" />
                  <span>{formatNumber(Number(asset.borrowBalance) + Number(amount))}</span>
                </div>
              )}
            </div>
            <div className="borrow-limit">
              <span>
                <FormattedMessage id="Borrow Balance" />
              </span>
              {amount.value.isZero() || amount.value.isNaN() ? (
                <span>{formatNumber(borrowBalance, true, 2, '$')}</span>
              ) : (
                <div className="flex align-center just-between">
                  <span>{formatNumber(borrowBalance, true, 2, '$')}</span>
                  <img className="arrow-right-img" src={arrowRightImg} alt="arrow" />
                  <span>{formatNumber(newBorrowBalance, true, 2, '$')}</span>
                </div>
              )}
            </div>
            <div className="borrow-limit">
              <span>
                <FormattedMessage id="Borrow Limit Used" />
              </span>
              {amount.value.isZero() || amount.value.isNaN() ? (
                <span>{formatNumber(borrowPercent, false, 2, '', '%')}</span>
              ) : (
                <div className="flex align-center just-between">
                  <span>{formatNumber(borrowPercent, false, 2, '', '%')}</span>
                  <img className="arrow-right-img" src={arrowRightImg} alt="arrow" />
                  <span>{formatNumber(newBorrowPercent, false, 2, '', '%')}</span>
                </div>
              )}
            </div>
            <Progress
              percent={newBorrowPercent.toNumber()}
              className="apy-progress"
              strokeColor="linear-gradient(270deg, #498885 0%, #BDF4F1 100%)"
              trailColor={`${ui.theme === 'dark' ? '#000000' : '#FFFFFF'}`}
              strokeWidth={7}
              showInfo={false}
            />
          </div>
          <Button
            className="button dark"
            disabled={
              isLoading ||
              amount.value.isZero() ||
              amount.value.isNaN() ||
              amount.value.isGreaterThan(asset.liquidity.div(asset.tokenPrice)) ||
              newBorrowPercent.isGreaterThan(100) ||
              (!getBigNumber(asset.borrowCaps || 0).isZero() &&
                amount.value.plus(asset.totalBorrows).isGreaterThan(asset.borrowCaps))
            }
            onClick={handleBorrow}
          >
            <FormattedMessage id="Borrow" />
          </Button>
          <div className="flex flex-wrap just-between">
            {[
              { img: asset.img, label: 'Borrow APY', val: `${formatNumber(asset.borrowApy, false, 2, '', '%')}` },
              { img: coinImg, label: 'CHEE APY', val: `${formatNumber(asset.cheeBorrowApy, false, 2, '', '%')}` },
            ].map((item) => (
              <div key={item.label} className="trans-proportion">
                <img className="asset-img" src={item.img} alt="asset" />
                <span>
                  {/* Supply APY */}
                  <FormattedMessage id={item.label} />
                </span>
                <span>{item.val}</span>
              </div>
            ))}
          </div>
        </TabContent>
        {borrowBalanceIsZero ? (
          <div className="flex flex-column align-center approve-enable borrow-limit-modal">
            <FormattedMessage id="Please enable supplied as collateral or add more collateral." />
          </div>
        ) : null}
      </Form>
    </TabContentWrapper>
  );
}

BorrowTab.propTypes = {
  asset: PropTypes.object,
  settings: PropTypes.object,
  onCancel: PropTypes.func,
  setSetting: PropTypes.func.isRequired,
};

BorrowTab.defaultProps = {
  asset: {},
  settings: {},
  onCancel: () => {},
};

const mapStateToProps = ({ account, ui }) => ({
  settings: account.setting,
  ui,
});

const mapDispatchToProps = (dispatch) => {
  const { setSetting } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting,
    },
    dispatch
  );
};

export default compose(connectAccount(mapStateToProps, mapDispatchToProps))(BorrowTab);
