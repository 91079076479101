import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button } from 'antd';
import arrowRightImg from 'assets/img/arrow-right.png';
import mintEnableModalBgDark from 'assets/img/mint-enable-modal-bg-dark.png';
import mintEnableModalBgLight from 'assets/img/mint-enable-modal-bg-light.png';
import warnIconDark from 'assets/img/vepools-warn-dark.svg';
import warnIconLight from 'assets/img/vepools-warn-light.svg';
import { connectAccount, accountActionCreators } from 'modules';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { BLOCKS_PER_MINUTE, CHEETOKEN, VETOKENNAME } from 'constants/address';
import BigNumber from 'bignumber.js';
import { formatNumber, getBigNumber } from 'utilities/number';
import { CloseOutlined } from '@ant-design/icons';
import { ModalContent, ProgressBar } from './style';

function MintModal({
  form,
  visible,
  selectedRow,
  mintProgressShow,
  cheeTokenBalanceOf,
  networkName,
  onMint,
  ui,
  onCancel,
}) {
  const [cheeTokenValue, setCheeTokenValue] = useState({ value: 0 });
  const [lockedDays, setLockedDays] = useState({ value: 0 });
  const name = CHEETOKEN[networkName];
  const veTokenName = VETOKENNAME[networkName];

  const APY = getBigNumber(selectedRow.interestPerBlock * BLOCKS_PER_MINUTE[networkName] * 365 * 24 * 60)
    .div(getBigNumber(10).pow(18))
    .dp(4, 1)
    .toString(10);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, formValues) => {
      if (!err) {
        onMint({ amount: cheeTokenValue, lockedTime: (lockedDays * 24 * 60 * 60) / 3 });
      }
    });
  };
  const validateCheeTokenAmount = (value) => {
    if (value === '0') {
      return {
        validateStatus: 'error',
        errorMsg: 'Amount is required',
      };
    }
    const isNotAllowed = value >= selectedRow.maxCheeTokenAmount;
    if (isNotAllowed) {
      return {
        validateStatus: 'error',
        errorMsg: 'Input amount too large',
      };
    }
    return {
      validateStatus: 'success',
      errorMsg: null,
    };
  };
  const onStakeInput = ({ value }) => {
    setCheeTokenValue({ ...validateCheeTokenAmount(value), value: +value });
  };

  const validateLockedDaysAmount = (value) => {
    if (value === '0') {
      return {
        validateStatus: 'error',
        errorMsg: 'Amount is required',
      };
    }
    const temp = getBigNumber(value || 0);
    const isNotAllowed = value >= selectedRow.maxDays;
    if (isNotAllowed) {
      return {
        validateStatus: 'error',
        errorMsg: 'Input amount too large',
      };
    }
    return {
      validateStatus: 'success',
      errorMsg: null,
    };
  };
  const onWithdrawInput = ({ value }) => {
    setLockedDays({ ...validateLockedDaysAmount(value), value: +value });
  };
  return (
    <Modal
      className="connect-modal"
      width={500}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable={false}
      maskClosable
      centered
    >
      <ModalContent className="flex flex-column align-center just-center">
        <CloseOutlined className="close-btn pointer" onClick={onCancel} />
        <div className={`flex flex-column align-center just-center header-content ${mintProgressShow ? '' : 'radius'}`}>
          <p className="title">Mint</p>
        </div>
        {mintProgressShow && <ProgressBar role="progressbar" aria-hidden="false" aria-label="notification timer" />}

        <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <div className="mint-content-wrapper">
            <div className="mint-content">
              <div className="content-item">
                <div className="coin-type" style={{ color: ui.theme === 'dark' ? '#FFFFFF' : '#000000' }}>
                  <img src={selectedRow.img} alt="" />
                  {selectedRow.Asset}
                </div>
                <div className="coin-info">
                  <div>
                    <span>ID: </span>
                    {selectedRow.ID}
                  </div>
                  <div>
                    <span>Balance: </span>
                    {selectedRow.Balance}
                  </div>
                  <div>
                    <span>Locked Until: </span>
                    {selectedRow.date}
                  </div>
                </div>
              </div>
              <div
                className="line"
                style={{
                  backgroundColor: ui.theme === 'dark' ? '#2F413B' : '#000000',
                  opacity: ui.theme === 'dark' ? 1 : 0.1,
                }}
              />

              <div className="content-item coin-count">
                <Form.Item
                  name="cheeTokenValue"
                  rules={[{ required: true, message: 'Please input amount!' }]}
                  validateStatus={cheeTokenValue.validateStatus}
                  help={cheeTokenValue.errorMsg}
                >
                  <div className="label-items flex just-between">
                    <span
                      className="receive-label"
                      style={{
                        color: ui.theme === 'dark' ? '#ffffff' : '#333333',
                      }}
                    >
                      {name} to borrow
                    </span>

                    <NumberFormat
                      autoFocus
                      className="input"
                      placeholder="input amount here"
                      style={{
                        color: ui.theme === 'dark' ? '#ffffff' : '#333333',
                        borderColor: ui.theme === 'dark' ? '#000000' : 'rgb(47 65 59 / 10%)',
                        paddingLeft: '10px',
                      }}
                      value={cheeTokenValue === 0 ? null : cheeTokenValue}
                      onValueChange={onStakeInput}
                      isAllowed={({ value }) => {
                        return value < selectedRow.maxCheeTokenAmount;
                      }}
                      thousandSeparator
                      allowNegative={false}
                    />
                  </div>
                </Form.Item>
                <div className="count-labels-wrapper flex just-between">
                  <div className="count-label">
                    <span className="spe-font"> Wallet</span>
                    <span>
                      Balance: {cheeTokenBalanceOf || 0} {name}
                    </span>
                  </div>
                  <div className="num-list flex pointer">
                    {[10, 25, 50].map((item, index) => (
                      <span
                        onClick={() => {
                          setCheeTokenValue((selectedRow.maxCheeTokenAmount * item) / 100);
                        }}
                        key={index}
                      >
                        {item}%
                      </span>
                    ))}
                    <span
                      className="flex align-center pointer"
                      onClick={() => {
                        setCheeTokenValue(selectedRow.maxCheeTokenAmount);
                      }}
                    >
                      MAX
                      <img
                        src={ui.theme === 'dark' ? warnIconDark : warnIconLight}
                        alt="warnIconDark"
                        style={{ marginLeft: '2px' }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="line"
                style={{
                  backgroundColor: ui.theme === 'dark' ? '#2F413B' : '#000000',
                  opacity: ui.theme === 'dark' ? 1 : 0.1,
                }}
              />
              <div className="content-item mint-price-content flex-column spe">
                <Form.Item
                  name="lockedDays"
                  rules={[{ required: true, message: 'Please input amount!' }]}
                  validateStatus={lockedDays.validateStatus}
                  help={lockedDays.errorMsg}
                >
                  <div className="label-items flex just-between">
                    <span
                      className="receive-label"
                      style={{
                        color: ui.theme === 'dark' ? '#ffffff' : '#333333',
                      }}
                    >
                      borrow time in days
                    </span>

                    <div className="tag-input">
                      <NumberFormat
                        autoFocus
                        className="input"
                        style={{
                          color: ui.theme === 'dark' ? '#ffffff' : '#333333',
                          borderColor: ui.theme === 'dark' ? '#000000' : 'rgb(47 65 59 / 10%)',
                          paddingLeft: '10px',
                        }}
                        value={lockedDays === 0 ? null : lockedDays}
                        onValueChange={({ value }) => {
                          setLockedDays(value);
                        }}
                        isAllowed={({ value }) => {
                          return value < selectedRow.maxDays;
                        }}
                        thousandSeparator
                        allowNegative={false}
                        placeholder="0"
                      />
                      <span
                        className="tag"
                        onClick={() => {
                          setLockedDays(selectedRow.maxDays);
                        }}
                      >
                        MAX
                      </span>
                    </div>
                  </div>
                </Form.Item>
                <div
                  className="mint-price-items"
                  style={{
                    backgroundColor: ui.theme === 'dark' ? '#101C18' : '#F6F6F6',
                  }}
                >
                  <div className="mint-price-item flex just-between">
                    <span className="left flex align-center">
                      <span className="padding-right">Interests in {veTokenName} (APY </span>
                      <span>{(APY * 100).toFixed(2)}</span>
                      <span>%)</span>
                      <img
                        src={ui.theme === 'dark' ? warnIconDark : warnIconLight}
                        alt="warnIconDark"
                        style={{ marginLeft: '4px' }}
                      />
                    </span>
                    <span className="right">
                      {cheeTokenValue && lockedDays
                        ? formatNumber(((cheeTokenValue * APY) / 365) * lockedDays, true, 5)
                        : '--'}
                    </span>
                  </div>
                  <div className="mint-price-item flex just-between">
                    <span className="left">Fees in {veTokenName}</span>
                    <span className="right">
                      {cheeTokenValue ? (cheeTokenValue * selectedRow.feeRate) / 1e18 : '--'}
                    </span>
                  </div>
                  <div className="mint-price-item flex just-between">
                    <span className="left">Repay Before</span>
                    <span className="right">{moment(moment().add(lockedDays, 'day')).format('MM/DD/YYYY HH:mm')}</span>
                  </div>
                  <div className="mint-price-item flex just-between">
                    <span className="left">Grace Period</span>
                    <span className="right">{selectedRow.graceHours} hours</span>
                  </div>
                </div>
              </div>
            </div>
            <Form.Item className="p-l-20 p-r-20">
              <Button
                htmlType="submit"
                className={!lockedDays || !cheeTokenValue ? 'btn' : 'btn btn-hover'}
                style={{
                  color: '#FFFFFF',
                  border: 'none',
                }}
                disabled={!!(!lockedDays || !cheeTokenValue) || !!mintProgressShow}
              >
                Mint
              </Button>
            </Form.Item>
          </div>
        </Form>
      </ModalContent>
    </Modal>
  );
}

MintModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
};

MintModal.defaultProps = {
  visible: false,
  onCancel: () => {},
};

const mapStateToProps = ({ account, ui }) => ({
  settings: account.setting,
  ui,
});

const mapDispatchToProps = (dispatch) => {
  const { setSetting } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting,
    },
    dispatch
  );
};

export default compose(
  connectAccount(mapStateToProps, mapDispatchToProps),
  Form.create({ name: 'mint_form' })
)(MintModal);
