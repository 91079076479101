import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import QRCode from 'qrcode.react';
import { NOT_INSTALLED } from 'constants/hint';
import arrowRightImg from 'assets/img/arrow-right.png';
import { networkInfo, networkList } from 'constants/network';
import { onboard } from 'components/App';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import MainLayout from 'components/layout/wrap';
import { connectAccount, accountActionCreators } from 'modules';
import { initialState } from 'modules/initialState';
import { CloseOutlined } from '@ant-design/icons';
import { ModalContent } from './style';

function NetworkModal({ visible, web3, wcUri, error, awaiting, onCancel, setSetting }) {
  const changeNetwork = (key) => {
    const netWorkInfo = networkInfo[key];
    onboard.setChain({ chainId: netWorkInfo.chainId }).then((r) => {
      setSetting({ assetList: [], markets: [] });
      onCancel();
    });
  };
  const MetaMaskStatus = () => {
    if (error && error.message === NOT_INSTALLED) {
      return (
        <p className="center">
          We could not locate a supported web3 browser extension. We recommend using MetaMask.
          <a href="https://metamask.io/" target="_blank" rel="noopener noreferrer">
            Download MetaMask here.
          </a>
        </p>
      );
    }
    if (error) {
      return <span>{error.message}</span>;
    }
    if (!web3 && awaiting) {
      return <span>MetaMask loading...</span>;
    }
    if (!web3) {
      return <span>Please open and allow MetaMask</span>;
    }
    return null;
  };

  return (
    <Modal
      className="connect-modal"
      width={636}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable={false}
      maskClosable
      centered
    >
      <ModalContent className="flex flex-column align-center just-center">
        <CloseOutlined className="close-btn pointer" style={{ fontSize: 18, color: '#FFFFFF' }} onClick={onCancel} />
        <div className="flex flex-column align-center just-center header-content">
          <p className="title">Please choose the available network</p>
        </div>
        <div className="connect-wallet-content flex flex-wrap">
          {!wcUri && (
            <>
              {networkList.map((item, index) => (
                <div
                  key={index}
                  className="flex align-center just-between metamask-connect-btn"
                  onClick={() => changeNetwork(item.value)}
                >
                  <div className="flex align-center">
                    <img src={item.icon} alt="metamask" />
                    <span>{item.name}</span>
                  </div>
                </div>
              ))}
            </>
          )}
          {wcUri && (
            <div className="flex align-center just-center">
              <QRCode value={wcUri} size={256} />
            </div>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
}

NetworkModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  setSetting: PropTypes.func.isRequired,
};

NetworkModal.defaultProps = {
  visible: false,
  onCancel: () => {},
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

const mapDispatchToProps = (dispatch) => {
  const { setSetting } = accountActionCreators;
  return bindActionCreators(
    {
      setSetting,
    },
    dispatch
  );
};

export default compose(connectAccount(mapStateToProps, mapDispatchToProps))(NetworkModal);
