import celoImg from 'assets/img/networks/celo.png';
import bscImg from 'assets/img/networks/bsc.png';
import meterImg from 'assets/img/networks/meter.png';
import ethImg from 'assets/img/networks/eth.png';
import polygonImg from 'assets/img/networks/polygon.png';

const networkInfo = {
  'bsc-main': {
    chainId: '0x38', // 56
    chainName: 'BNB Chain Mainnet',
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com/'],
    shortName: 'BSC',
  },
  'celo-main': {
    chainId: '0xa4ec', // 42220
    chainName: 'Celo Mainnet',
    rpcUrls: ['https://forno.celo.org'],
    blockExplorerUrls: ['https://celoscan.io/'],
    shortName: 'CELO',
  },
  // 'meter-main': {
  //   chainId: '0x52', // 82
  //   chainName: 'Meter Mainnet',
  //   rpcUrls: ['https://rpc.meter.io/'],
  //   blockExplorerUrls: ['https://scan.meter.io/'],
  //   shortName: 'METER',
  // },
  'eth-main': {
    chainId: '0x1', // 1
    chainName: 'Ethereum Mainnet',
    rpcUrls: ['https://rpc.ankr.com/eth'],
    blockExplorerUrls: ['https://etherscan.io/'],
    shortName: 'ETH',
  },
  // 'polygon-main': {
  //   chainId: '0x89', // 137
  //   chainName: 'Polygon Mainnet',
  //   rpcUrls: [
  //     'https://rpc-mainnet.matic.network',
  //     'https://matic-mainnet.chainstacklabs.com',
  //     'https://rpc-mainnet.maticvigil.com',
  //     'https://rpc-mainnet.matic.quiknode.pro',
  //     'https://matic-mainnet-full-rpc.bwarelabs.com',
  //   ],
  //   blockExplorerUrls: ['https://polygonscan.com/'],
  //   shortName: 'POLYGON',
  // },
};

const networkList = [
  {
    name: 'Celo Mainnet',
    value: 'celo-main',
    icon: celoImg,
    supportBridge: false,
    supportTrans: true,
    supportNFT: true,
    supportNFTBtnDisabled: true,
    shortName: 'CELO',
    supportNFTSubgraph: false,
    supportLiquidation: true,
  },
  // {
  //   name: 'Meter Mainnet',
  //   value: 'meter-main',
  //   icon: meterImg,
  //   supportBridge: false,
  //   supportTrans: true,
  //   supportNFT: true,
  //   supportNFTBtnDisabled: true,
  //   shortName: 'METER',
  //   supportNFTSubgraph: false,
  //   supportLiquidation: true,
  // },
  // {
  //   name: 'BNB Chain Mainnet',
  //   value: 'bsc-main',
  //   icon: bscImg,
  //   supportBridge: false,
  //   supportTrans: true,
  //   supportNFT: false,
  //   supportNFTBtnDisabled: true,
  //   shortName: 'BSC',
  //   supportNFTSubgraph: false,
  //   supportLiquidation: true,
  // },
  // {
  //   name: 'Ethereum Mainnet',
  //   value: 'eth-main',
  //   icon: ethImg,
  //   supportBridge: false,
  //   supportTrans: false,
  //   supportNFT: true,
  //   supportNFTBtnDisabled: false,
  //   shortName: 'ETH',
  //   supportNFTSubgraph: false,
  //   supportLiquidation: false,
  // },
  // {
  //   name: 'Polygon Mainnet',
  //   value: 'polygon-main',
  //   icon: polygonImg,
  //   supportBridge: false,
  //   supportTrans: false,
  //   supportNFT: false,
  //   supportNFTBtnDisabled: true,
  //   shortName: 'POLYGON',
  //   supportNFTSubgraph: false,
  //   supportLiquidation: false,
  // },
];
export { networkInfo, networkList };
