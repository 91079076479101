import { DISTRIBUTED } from 'constants/address';
import get from 'lodash/get';

export const totalDistributed = (networkName, coin) => {
  if (!networkName) return 0;
  if (!Object.keys(DISTRIBUTED).includes(networkName)) return 0;
  const now = new Date();
  const disMarketKeys = Object.keys(DISTRIBUTED[networkName]);
  const total = disMarketKeys.reduce((acc, curr) => {
    let day = (now - DISTRIBUTED[networkName][curr].distributedTime) / (1000 * 3600 * 24);
    return acc + day * DISTRIBUTED[networkName][curr].perDay;
  }, 0);

  return total.toFixed(4);
};

export const getPerday = (networkName, coin) => {
  return get(DISTRIBUTED, [networkName, coin, 'perDay'], 0);
};
