import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import arrowRightImg from 'assets/img/arrow-right.png';
import mintEnableModalBgDark from 'assets/img/mint-enable-modal-bg-dark.png';
import mintEnableModalBgLight from 'assets/img/mint-enable-modal-bg-light.png';
import izi from 'assets/img/coins/izi.png';
import { connectAccount, accountActionCreators } from 'modules';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import LoadingSpinner from 'components/common/loadingSpinner';
import { LoadingBgWrapper } from 'components/common/style';
import { CloseOutlined } from '@ant-design/icons';
import { ModalContent, ProgressBar } from './style';

function MintEnableModal({ visible, selectedRow, mintEnableProgressShow, ui, onApprove, wcUri, onCancel }) {
  // click approve
  const enable = () => {
    onApprove();
  };
  return (
    <Modal
      className="connect-modal"
      width={500}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable={false}
      maskClosable
      centered
    >
      <ModalContent className="flex flex-column align-center just-center">
        {mintEnableProgressShow && (
          <LoadingBgWrapper>
            <LoadingSpinner />
          </LoadingBgWrapper>
        )}
        {/* {mintEnableProgressShow && (
          <ProgressBar role="progressbar" aria-hidden="false" aria-label="notification timer" />
        )} */}

        <CloseOutlined
          className="close-btn pointer"
          style={{ fontSize: 18, color: '#FFFFFF' }}
          onClick={() => {
            onCancel();
          }}
        />

        <div
          className={`flex flex-column align-center just-center header-content ${
            mintEnableProgressShow ? '' : 'radius'
          }`}
        >
          <p className="title">Mint</p>
        </div>
        <div
          className="mint-content-wrapper"
          style={{ backgroundImage: `url(${ui.theme === 'dark' ? mintEnableModalBgDark : mintEnableModalBgLight})` }}
        >
          <div className="mint-content flex flex-wrap">
            <div className="content-item">
              <div className="coin-type" style={{ color: ui.theme === 'dark' ? '#FFFFFF' : '#000000' }}>
                <img src={selectedRow.img} alt="" />
                {selectedRow.Asset}
              </div>
              <div className="coin-info">
                <div>
                  <span>ID: </span>
                  {selectedRow.ID}
                </div>
                <div>
                  <span>Balance: </span>
                  {selectedRow.Balance}
                </div>
                <div>
                  <span>Locked Until: </span>
                  {selectedRow.date}
                </div>
              </div>
            </div>
          </div>
          <div className="p-l-20 p-r-20">
            <Button
              className={mintEnableProgressShow ? 'btn' : 'btn btn-hover'}
              style={{
                color: '#FFFFFF',
                border: 'none',
              }}
              onClick={() => enable()}
              disabled={!!mintEnableProgressShow}
            >
              Enable
            </Button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}

MintEnableModal.propTypes = {
  visible: PropTypes.bool,
  wcUri: PropTypes.string,
  onCancel: PropTypes.func,
};

MintEnableModal.defaultProps = {
  visible: false,
  wcUri: null,
  onCancel: () => {},
};

const mapStateToProps = ({ account, ui }) => ({
  settings: account.setting,
  ui,
});

const mapDispatchToProps = (dispatch) => {
  const { setSetting } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting,
    },
    dispatch
  );
};

export default compose(connectAccount(mapStateToProps, mapDispatchToProps))(MintEnableModal);
