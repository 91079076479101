import React from 'react';
import PropTypes from 'prop-types';
import loadingImg from 'assets/img/loading.svg';
import clockImg from 'assets/img/icons/clock.svg';

import { ComingSoonWrapper } from './style';

function ComingSoon({ size }) {
  return (
    <ComingSoonWrapper className="flex align-center just-center">
      <img src={clockImg} alt="loading" style={{ fontSize: size }} />
      <div className="desc">Stay Tuned!</div>
      <div className="title">-This Page Is Coming Soon-</div>
    </ComingSoonWrapper>
  );
}

ComingSoon.propTypes = {
  size: PropTypes.number,
};

ComingSoon.defaultProps = {
  size: 64,
};

export default ComingSoon;
