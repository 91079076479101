import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Row, Col } from 'antd';
import arrowRightImg from 'assets/img/arrow-right.png';
import mintEnableModalBgDark from 'assets/img/mint-enable-modal-bg-dark.png';
import mintEnableModalBgLight from 'assets/img/mint-enable-modal-bg-light.png';
import izi from 'assets/img/coins/izi.png';
import warnIconDark from 'assets/img/vepools-warn-dark.svg';
import warnIconLight from 'assets/img/vepools-warn-light.svg';
import { connectAccount, accountActionCreators } from 'modules';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { CHEETOKEN, VETOKENNAME, VETOKENASSET } from 'constants/address';
import { formatNumber, getBigNumber } from 'utilities/number';
import NumberFormat from 'react-number-format';
import { FormattedMessage } from 'react-intl';
import { getNum } from 'utilities/getAccount';
import { CloseOutlined } from '@ant-design/icons';
import { ModalContent, ProgressBar } from './style';

function LiquidationModal({
  markets,
  visible,
  selectedRow,
  liquidationEnableProgressShow,
  currentCheeTokenEnabled,
  currentVeTokenEnabled,
  networkName,
  onLiquidation,
  ui,
  wcUri,
  onCancel,
  loading = false,
}) {
  const name = CHEETOKEN[networkName];
  const veTokenName = VETOKENNAME[networkName];
  const vename = VETOKENASSET[networkName];
  const [currentSupply, setCurrentSupply] = useState(null);
  const [currentBorrow, setCurrentBorrow] = useState(null);
  const [currentBorrowItem, setCurrentBorrowItem] = useState(null);
  const [currentSupplyItem, setCurrentSupplyItem] = useState(null);
  const [amount, setAmount] = useState(getBigNumber());

  const handleData = (data) => {
    data.sort((a, b) => {
      return `${a.name}`.localeCompare(`${b.name}`);
    });
    return data;
  };

  const handleMax = (item) => {
    // const supplyAmountToBorrowNum = getNum(currentBorrowItem.cToken, currentSupplyItem.amount, markets);
    // const minNum =
    //   Number(supplyAmountToBorrowNum) < Number(currentBorrowItem.value)
    //     ? Number(supplyAmountToBorrowNum)
    //     : Number(currentBorrowItem.value);
    // const lastMin = minNum < Number(item?.wallet) ? minNum : Number(item?.wallet);
    const lastMin =
      Number(currentBorrowItem.value) < Number(item?.wallet) ? Number(currentBorrowItem.value) : Number(item?.wallet);
    setAmount(getBigNumber(lastMin));
  };
  useEffect(() => {
    if (!visible) {
      setAmount(getBigNumber());
      setCurrentBorrow(null);
      setCurrentSupply(null);
    }
  }, [visible]);
  return (
    <Modal
      className="connect-modal"
      width={500}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable={false}
      maskClosable
      centered
    >
      <ModalContent className="flex flex-column align-center just-center">
        {liquidationEnableProgressShow && (
          <ProgressBar role="progressbar" aria-hidden="false" aria-label="notification timer" />
        )}
        <CloseOutlined
          className="close-btn pointer"
          onClick={() => {
            onCancel();
          }}
        />

        <div
          className={`flex flex-column align-center just-center header-content ${
            liquidationEnableProgressShow ? '' : 'radius'
          }`}
        >
          <p className="title">Liquidate</p>
        </div>
        <div className="mint-content-wrapper">
          <div className="mint-content">
            <div>
              <div className="liquidation-title">
                <Row>
                  <Col lg={{ span: 8 }}>
                    <span>Supply Assets</span>
                  </Col>
                  <Col lg={{ span: 8 }} className="center">
                    <span>Supply</span>
                  </Col>
                  <Col lg={{ span: 8 }} className="right">
                    <span>Amount</span>
                  </Col>
                </Row>
              </div>

              {handleData(selectedRow?.supplyList)?.map((item, index) => {
                return (
                  <div
                    className={`liquidation-item ${item.cToken === currentSupply ? 'active' : ''}`}
                    key={index}
                    onClick={() => {
                      setCurrentSupply(item.cToken);
                      setCurrentSupplyItem(item);
                    }}
                  >
                    <Row>
                      <Col lg={{ span: 8 }}>
                        <span>{item.name.toUpperCase()}</span>
                      </Col>
                      <Col lg={{ span: 8 }} className="center">
                        <span>{formatNumber(item.value, true, 4)}</span>
                      </Col>
                      <Col lg={{ span: 8 }} className="right">
                        <span>{formatNumber(item.amount, true, 2, '$')}</span>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </div>
            <div className="m-t-30">
              <div className="liquidation-title">
                <Row>
                  <Col lg={{ span: 6 }}>
                    <span>Borrow Assets</span>
                  </Col>
                  <Col lg={{ span: 6 }} className="center">
                    <span>Borrow</span>
                  </Col>
                  <Col lg={{ span: 6 }} className="center">
                    <span>Amount</span>
                  </Col>
                  <Col lg={{ span: 6 }} className="right">
                    <span>Wallet</span>
                  </Col>
                </Row>
              </div>
              {handleData(selectedRow?.borrowList)?.map((item, index) => {
                return (
                  <div
                    className={`liquidation-item ${item.cToken === currentBorrow ? 'active' : ''}`}
                    key={index}
                    onClick={() => {
                      setCurrentBorrow(item.cToken);
                      setCurrentBorrowItem(item);
                      if (Number(amount) > Number(item.wallet)) {
                        setAmount(getBigNumber(item.wallet));
                      }
                    }}
                  >
                    <Row>
                      <Col lg={{ span: 6 }}>
                        <span>{item.name.toUpperCase()}</span>
                      </Col>
                      <Col lg={{ span: 6 }} className="center">
                        <span>{formatNumber(item.value, true, 4)}</span>
                      </Col>
                      <Col lg={{ span: 6 }} className="center">
                        <span>{formatNumber(item.amount, true, 2, '$')}</span>
                      </Col>
                      <Col lg={{ span: 6 }} className="right">
                        <span>{item.wallet}</span>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </div>
            <div className="liquidation-label">RequestedAmountClose</div>
            <div className="content-item just-between second p-l-20 p-r-20">
              <div className="flex align-center input-wrapper">
                <NumberFormat
                  autoFocus
                  value={amount.isZero() ? null : amount.toString(10)}
                  onValueChange={({ value }) => {
                    if (Number(value) > Number(currentBorrowItem?.wallet)) {
                      setAmount(getBigNumber(currentBorrowItem?.wallet));
                    } else {
                      setAmount(getBigNumber(value));
                    }
                  }}
                  thousandSeparator
                  allowNegative={false}
                  placeholder="0"
                />

                <span className="coin-name">
                  {selectedRow?.borrowList?.find((i) => i.cToken === currentBorrow)?.name?.toUpperCase()}
                </span>
              </div>
            </div>
            <div className="coin-btn p-l-20 p-r-20" role="presentation" onClick={() => handleMax(currentBorrowItem)}>
              MAX
            </div>

            <div className="content-item p-l-20 p-r-20">
              <Button
                className="button liquidation"
                disabled={amount.isNaN() || amount.isZero() || !currentSupply || !currentBorrow}
                onClick={() => {
                  onLiquidation(selectedRow, currentBorrow, currentSupply, amount);
                }}
                loading={loading}
              >
                <FormattedMessage id="Liquidate" />
              </Button>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}

LiquidationModal.propTypes = {
  visible: PropTypes.bool,
  wcUri: PropTypes.string,
  onCancel: PropTypes.func,
};

LiquidationModal.defaultProps = {
  visible: false,
  wcUri: null,
  onCancel: () => {},
};

const mapStateToProps = ({ account, ui }) => ({
  settings: account.setting,
  ui,
});

const mapDispatchToProps = (dispatch) => {
  const { setSetting } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting,
    },
    dispatch
  );
};

export default compose(connectAccount(mapStateToProps, mapDispatchToProps))(LiquidationModal);
