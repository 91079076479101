/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Select } from 'antd';
import Icon from '@ant-design/icons';
import { connectUI } from 'modules/ui/connectUI';
import { EXPLORER_LINK } from 'constants/address';
import { connectAccount, accountActionCreators } from 'modules';
import checkIsValidNetwork from 'utilities/checkIsValidNetwork';
import NetworkModal from 'components/common/networkModal';
import { onboard } from 'components/App';
import toast from 'components/common/toast';
import { networkInfo, networkList } from 'constants/network';
import { networkInfo as mainNetworkInfo } from 'constants/network-main';
import { networkInfo as testNetworkInfo } from 'constants/network-test';
import cloneDeep from 'lodash/cloneDeep';
import { networkStatus } from 'modules/initialState';
import avatarImg from 'assets/img/chee-64.png';
import { NET_IMG } from 'constants/network-img';
import { LANG_LIST } from 'constants/lang';
import { FooterWrapper, NetworkWrap, WalletWrap, BlockWrap } from './style';
import ThemeSwitch from '../components/theme-switch';

const { Option } = Select;

const ICON_ARROW = () => (
  <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.16795 4.75192C3.56377 5.34566 4.43623 5.34566 4.83205 4.75192L6.96353 1.5547C7.40657 0.890145 6.93018 0 6.13148 0H1.86852C1.06982 0 0.59343 0.890146 1.03647 1.5547L3.16795 4.75192Z"
      fill="#008A83"
    />
  </svg>
);

function Top({ history, settings, setSetting, ui, changeTheme, changeLanguage, changeStatus }) {
  const [isOpenConnectModal, setIsOpenConnectModal] = useState(false);
  const [isOpenDisConnectModal, setIsOpenDisConnectModal] = useState(false);
  const [isMarketInfoUpdating, setMarketInfoUpdating] = useState(false);
  const [error, setError] = useState('');
  const [web3, setWeb3] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [awaiting, setAwaiting] = useState(false);
  const [totalCaiMinted, setTotalCaiMinted] = useState('0');
  const [wcUri, setWcUri] = useState(null);
  const [ensureNoAddress, setEnsureNoAddress] = useState(false);
  // hide network Modal if using WalletConnect
  const [isOpenNetworkModal, setIsOpenNetworkModal] = useState(false);
  const [isHiddenSwitchNetWork, setHiddenSwitchNetWork] = useState(false);
  // hide subgraph error
  const [isHiddenSubgraph, setHiddenSubgraph] = useState(true);

  useEffect(() => {
    if (settings.chainId !== -1 && Object.values(networkInfo).every((item) => item.chainId !== settings.chainId)) {
      setIsOpenNetworkModal(true);
    }
  }, [settings.chainId]);

  const connectWallet = async () => {
    try {
      onboard.connectWallet();
    } catch (walleterror) {
      // console.error(walleterror);
    }
  };

  const defaultPath = history.location.pathname.split('/')[1];

  const changeT = () => {
    changeTheme();
  };

  const changeL = (lang) => {
    changeLanguage(lang);
  };

  useEffect(() => {
    if (!settings.networkName) return;
    const isSupportTrans = networkList.find(
      (networkItem) => settings.networkName === networkItem.shortName
    )?.supportTrans;
    if (!isSupportTrans) {
      setHiddenSubgraph(true);
    } else {
      setHiddenSubgraph(false);
    }
  }, [isHiddenSubgraph]);
  return (
    <FooterWrapper>
      <BlockWrap className="network-wrap">
        <div className="flex align-center">
          <div
            className={`status-circle ${!isHiddenSubgraph && ui.subgraphStatus === 'error' && 'subgraph-error'}`}
            target="_blank"
          />
          {isHiddenSubgraph
            ? null
            : ui.subgraphStatus === 'error' && (
                <div className="subgraph-error-msg">
                  <FormattedMessage id="Subgraph Error" />
                </div>
              )}

          <a href={EXPLORER_LINK[settings.networkName]} target="_blank" rel="noreferrer">
            {/* Latest Block:  */}
            <span className="label">
              <FormattedMessage id="Latest Block" />
            </span>
            <span> {settings.latestBlockNumber || 0}</span>
          </a>
        </div>
      </BlockWrap>

      <WalletWrap className="network-wrap" onClick={connectWallet}>
        <div className="label">Wallet</div>
        <div className="network-name">
          <span className="img-wrap">
            <img src={avatarImg} alt="" />
          </span>
          <span className="network-name">
            {!settings.selectedAddress
              ? 'Connect'
              : `${settings.selectedAddress.substr(0, 6)}...${settings.selectedAddress.substr(
                  settings.selectedAddress.length - 4,
                  4
                )}`}
          </span>
        </div>
      </WalletWrap>
      <NetworkWrap
        className="network-wrap"
        onClick={() => {
          // eslint-disable-next-line no-unused-expressions
          !isHiddenSwitchNetWork && setIsOpenNetworkModal(true);
        }}
      >
        <div className="label">Network</div>
        {isHiddenSwitchNetWork ? (
          settings.networkName === 'Wrong netWork' ? (
            <>
              <span className="img-wrap">
                <img src={NET_IMG[settings.networkName]} alt="" />
              </span>
              <span className="network-name connect-btn red">{settings.networkName}</span>
            </>
          ) : (
            <>
              <span className="img-wrap">
                <img src={NET_IMG[settings.networkName]} alt="" />
              </span>
              <span className="network-name connect-btn">{settings.networkName}</span>
            </>
          )
        ) : (
          <>
            <span className="img-wrap">
              <img src={NET_IMG[settings.networkName]} alt="" />
            </span>
            <span className="connect-btn network-name">{settings.networkName}</span>
          </>
        )}
      </NetworkWrap>
      <NetworkModal visible={isOpenNetworkModal} onCancel={() => setIsOpenNetworkModal(false)} />
      <Select
        suffixIcon={<Icon component={ICON_ARROW} />}
        size="large"
        value={ui.lang}
        style={{ width: 140 }}
        className="changeLanguage"
        onChange={changeL}
        dropdownStyle={{
          background: `${ui.theme === 'dark' ? '#29302f' : '#FFFFFF'}`,
          color: `${ui.theme === 'dark' ? '#FFFFFF' : '#28302EDB'}`,
        }}
      >
        {LANG_LIST.map((item) => (
          <Option className="language-option" style={{ fontSize: 13 }} key={item.value} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
      {/* <ThemeSwitch changeTheme={changeTheme} ui={ui} /> */}
    </FooterWrapper>
  );
}

Top.propTypes = {
  history: PropTypes.object,
  settings: PropTypes.object,
  setSetting: PropTypes.func.isRequired,
  ui: PropTypes.object,
};

Top.defaultProps = {
  settings: {},
  history: {},
  ui: {},
};

const mapStateToProps = ({ account, ui }) => ({
  settings: account.setting,
  ui,
});

const mapDispatchToProps = (dispatch) => {
  const { setSetting } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting,
    },
    dispatch
  );
};

export default compose(
  withRouter,
  connectAccount(mapStateToProps, mapDispatchToProps),
  connectUI(mapStateToProps, undefined)
)(Top);
