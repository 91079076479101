import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { Select } from 'antd';
import { connectAccount, accountActionCreators } from 'modules';
import OverviewChart from 'components/common/overviewChat';
import { promisify } from 'utilities/promisify';
import { CONTRACT_BASE_TOKEN, CONTRACT_CTOKEN_ADDRESS, CONTRACT_TOKEN_ADDRESS, GRAPH_TICKER } from 'constants/address';
import addToken from 'utilities/addToken';
import { formatNumber, getBigNumber } from 'utilities/number';
import { Card, Column, Row } from 'components/common/style';
import { CardWrapper, AssetSelectWrapper } from './style';

const { Option } = Select;
const abortController = new AbortController();

function Overview({ settings, getMarketHistory, ui }) {
  const [currentAsset, setCurrentAsset] = useState(null);
  const [data, setData] = useState([]);
  const [marketInfo, setMarketInfo] = useState({});
  const [currentSupplyAPY, setCurrentSupplyAPY] = useState(0);
  const [currentBorrowAPY, setCurrentBorrowAPY] = useState(0);
  const getGraphData = async (asset, type, limit) => {
    let tempData = [];
    const res = await promisify(getMarketHistory, { asset, type, limit });
    tempData = res.data.result
      .map((m) => {
        return {
          createdAt: m.createdAt,
          supplyApy: +getBigNumber(m.supplyApy || 0)
            .dp(8, 1)
            .toString(10),
          borrowApy: +getBigNumber(m.borrowApy || 0)
            .dp(8, 1)
            .toString(10),
        };
      })
      .reverse();
    setData([...tempData]);
  };

  const getGovernanceData = useCallback(async () => {
    if (!currentAsset) return;
    if (settings.markets && settings.markets.length > 0) {
      const info = settings.markets.find((item) => item.underlyingSymbol.toLowerCase() === currentAsset);
      setMarketInfo(info || {});
    }
  }, [settings.markets, currentAsset]);

  useEffect(() => {
    getGovernanceData();
  }, [getGovernanceData]);

  useEffect(() => {
    if (currentAsset) {
      getGraphData(
        CONTRACT_CTOKEN_ADDRESS[settings.networkName][currentAsset].address,
        GRAPH_TICKER || null,
        24 * 7 // 1 week
      );
    }
    return function cleanup() {
      abortController.abort();
    };
  }, [settings.selectedAddress, currentAsset]);

  useEffect(() => {
    setCurrentAsset(CONTRACT_BASE_TOKEN[settings.networkName]);
  }, []);

  useEffect(() => {
    if (settings.assetList && settings.assetList.length > 0) {
      const currentMarketInfo =
        settings.assetList.filter((s) => s && s.id === currentAsset).length !== 0
          ? settings.assetList.filter((s) => s && s.id === currentAsset)[0]
          : {};
      const supplyApy = getBigNumber(currentMarketInfo.supplyApy);
      const borrowApy = getBigNumber(currentMarketInfo.borrowApy);
      const supplyApyWithCHEE = settings.withCHEE ? supplyApy.plus(currentMarketInfo.cheeSupplyApy) : supplyApy;
      const borrowApyWithCHEE = settings.withCHEE
        ? getBigNumber(currentMarketInfo.cheeBorrowApy).plus(borrowApy)
        : borrowApy;
      setCurrentSupplyAPY(supplyApyWithCHEE.dp(2, 1).toString(10));
      setCurrentBorrowAPY(borrowApyWithCHEE.dp(2, 1).toString(10));
    }
  }, [currentAsset, settings.marketType, settings.assetList, settings.withCHEE]);

  const handleChangeAsset = (value) => {
    setCurrentAsset(value);
  };

  if (!settings.decimals[currentAsset]) {
    return null;
  }

  return (
    <Card>
      <CardWrapper>
        <div className="flex align-center just-between">
          <div className="flex align-center just-between asset-select-wrapper">
            <AssetSelectWrapper className="flex align-center just-end" id="asset">
              <Row className="flex">
                <span className="left">
                  <Select
                    defaultValue={CONTRACT_BASE_TOKEN[settings.networkName]}
                    style={{ width: 150, marginRight: 10 }}
                    getPopupContainer={() => document.getElementById('asset')}
                    dropdownMenuStyle={{
                      backgroundColor: `${ui.theme === 'dark' ? '#000000' : '#FFFFFF'}`,
                      color: `${ui.theme === 'dark' ? '#ffffff' : '#35435B'}`,
                    }}
                    dropdownClassName="asset-select"
                    onChange={handleChangeAsset}
                  >
                    {Object.keys(CONTRACT_CTOKEN_ADDRESS[settings.networkName]).map((key, index) => (
                      <Option
                        className="flex align-center just-between"
                        value={CONTRACT_CTOKEN_ADDRESS[settings.networkName][key].id}
                        key={index}
                      >
                        <img
                          className="asset-img"
                          src={CONTRACT_TOKEN_ADDRESS[settings.networkName][key].asset}
                          alt="asset"
                        />{' '}
                        <span>{CONTRACT_TOKEN_ADDRESS[settings.networkName][key].symbol}</span>
                      </Option>
                    ))}
                  </Select>
                  {/* <div className="value">
                    <FormattedMessage id="Market Overview" />
                  </div> */}
                </span>
              </Row>
              {/* <div className="value">
                <FormattedMessage id="Market Overview" />
              </div> */}
            </AssetSelectWrapper>
          </div>
          {/* <p className="value">{`$${
              (settings.marketType || 'supply') === 'supply'
                ? getBigNumber(marketInfo.totalSupply || 0).div(getBigNumber(10).pow(settings.decimals[currentAsset].ctoken)).dp(2, 1).toString(10)
                : getBigNumber(marketInfo.totalBorrows || 0).div(getBigNumber(10).pow(settings.decimals[currentAsset].token)).dp(2, 1).toString(10)
            }`}
          </p> */}
        </div>
        {/* <div className="historic-label">
          <FormattedMessage id="Historical rates" />
        </div> */}
        <div className="description">
          <p className="apy-label">
            <FormattedMessage id="Supply APY" />
          </p>
          <p className={currentSupplyAPY >= 0 ? 'apy-value' : 'apy-value-red'}>{currentSupplyAPY}%</p>
        </div>
        <div className="description">
          <p className="apy-label">
            <FormattedMessage id="Borrow APY" />
          </p>
          <p className={currentBorrowAPY >= 0 ? 'apy-value' : 'apy-value-red'}>{currentBorrowAPY}%</p>
        </div>
        {/* <OverviewChart
          marketType={settings.marketType || 'supply'}
          data={data}
        /> */}
        <div className="description">
          <p className="label">
            <FormattedMessage id="Price" />
          </p>
          <p className="value">
            {`$${getBigNumber(marketInfo.underlyingPrice || 0)
              .div(getBigNumber(10).pow(18 + 18 - parseInt(settings.decimals[currentAsset]?.token, 10)))
              .dp(8, 1)
              .toString(10)}`}
          </p>
        </div>
        <div className="description">
          <p className="label">
            <FormattedMessage id="Market Liquidity" />
          </p>
          <p className="value">
            {`${formatNumber(
              getBigNumber(marketInfo.cash || 0).div(getBigNumber(10).pow(settings.decimals[currentAsset]?.token)),
              true,
              8
            )} ${marketInfo.underlyingSymbol || ''}`}
          </p>
        </div>
        {/* <div className="description">
          <p className="label">
            <FormattedMessage id="# of Suppliers" />
          </p>
          <p className="value">{formatNumber(marketInfo.supplierCount)}</p>
        </div>
        <div className="description">
          <p className="label">
            <FormattedMessage id="# of Borrowers" />
          </p>
          <p className="value">{formatNumber(marketInfo.borrowerCount)}</p>
        </div> */}
        <div className="description">
          <p className="label">
            <FormattedMessage id="Reserves" />
          </p>
          <p className="value">
            {`${getBigNumber(marketInfo.totalReserves || 0)
              .div(getBigNumber(10).pow(settings.decimals[currentAsset]?.token))
              .dp(8, 1)
              .toString(10)} ${marketInfo.underlyingSymbol || ''}`}
          </p>
        </div>
        <div className="description">
          <p className="label">
            <FormattedMessage id="Reserve Factor" />
          </p>
          <p className="value">{`${getBigNumber(marketInfo.reserveFactor || 0)
            .div(getBigNumber(10).pow(18))
            .multipliedBy(100)
            .dp(8, 1)
            .toString(10)}%`}</p>
        </div>
        <div className="description">
          <p className="label">
            <FormattedMessage id="Collateral Factor" />
          </p>
          <p className="value">{`${getBigNumber(marketInfo.collateralFactor || 0)
            .div(getBigNumber(10).pow(18))
            .times(100)
            .dp(2, 1)
            .toString(10)}%`}</p>
        </div>
        <div className="description">
          <p className="label">
            <FormattedMessage id="Total Supply" />
          </p>
          <p className="value">{`$${formatNumber(getBigNumber(marketInfo.totalSupplyUsd || 0))}`}</p>
        </div>
        <div className="description">
          <p className="label">
            <FormattedMessage id="Total Borrow" />
          </p>
          <p className="value">{`$${formatNumber(getBigNumber(marketInfo.totalBorrowsUsd || 0))}`}</p>
        </div>
        {/* <div className="description">
          <p className="label">
            <FormattedMessage id="Exchange Rate" />
          </p>
          <p className="value">
            {`1 ${marketInfo.underlyingSymbol || ''} = ${Number(
              getBigNumber(1)
                .div(
                  getBigNumber(marketInfo.exchangeRate).div(
                    getBigNumber(10).pow(
                      18 +
                        +parseInt(
                          settings.decimals[currentAsset || CONTRACT_BASE_TOKEN[settings.networkName]].token,
                          10
                        ) -
                        +parseInt(
                          settings.decimals[currentAsset || CONTRACT_BASE_TOKEN[settings.networkName]].ctoken,
                          10
                        )
                    )
                  )
                )
                .toString(10)
            ).toFixed(6)} ${marketInfo.symbol || ''}`}
          </p>
        </div> */}
      </CardWrapper>
    </Card>
  );
}

Overview.propTypes = {
  settings: PropTypes.object,
  getMarketHistory: PropTypes.func.isRequired,
};

Overview.defaultProps = {
  settings: {},
};

const mapStateToProps = ({ account, ui }) => ({
  settings: account.setting,
  ui,
});

const mapDispatchToProps = (dispatch) => {
  const { getMarketHistory } = accountActionCreators;

  return bindActionCreators(
    {
      getMarketHistory,
    },
    dispatch
  );
};

export default compose(connectAccount(mapStateToProps, mapDispatchToProps))(Overview);
