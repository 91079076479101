import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import arrowRightImg from 'assets/img/arrow-right.png';
import mintEnableModalBgDark from 'assets/img/mint-enable-modal-bg-dark.png';
import mintEnableModalBgLight from 'assets/img/mint-enable-modal-bg-light.png';
import izi from 'assets/img/coins/izi.png';
import warnIconDark from 'assets/img/vepools-warn-dark.svg';
import warnIconLight from 'assets/img/vepools-warn-light.svg';
import { connectAccount, accountActionCreators } from 'modules';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { CHEETOKEN, VETOKENNAME, VETOKENASSET } from 'constants/address';
import { formatNumber, getBigNumber } from 'utilities/number';
import { CloseOutlined } from '@ant-design/icons';
import { ModalContent, ProgressBar } from './style';

function RedeemModal({
  visible,
  selectedRow,
  redeemEnableProgressShow,
  currentCheeTokenEnabled,
  currentVeTokenEnabled,
  networkName,
  onApprove,
  onRedeem,
  ui,
  wcUri,
  onCancel,
}) {
  const name = CHEETOKEN[networkName];
  const veTokenName = VETOKENNAME[networkName];
  const vename = VETOKENASSET[networkName];
  return (
    <Modal
      className="connect-modal"
      width={500}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable={false}
      maskClosable
      centered
    >
      <ModalContent className="flex flex-column align-center just-center">
        {redeemEnableProgressShow && (
          <ProgressBar role="progressbar" aria-hidden="false" aria-label="notification timer" />
        )}
        <CloseOutlined
          className="close-btn pointer"
          onClick={() => {
            onCancel();
          }}
        />

        <div
          className={`flex flex-column align-center just-center header-content ${
            redeemEnableProgressShow ? '' : 'radius'
          }`}
        >
          <p className="title">Redeem</p>
        </div>
        <div className="mint-content-wrapper">
          <div className="mint-content">
            <div className="content-item just-between">
              <div className="coin-type" style={{ color: ui.theme === 'dark' ? '#FFFFFF' : '#000000' }}>
                <img src={selectedRow.img} alt="" />
                {name}
              </div>
              <span className="coin-price">{selectedRow.cheeNum}</span>
            </div>
            {currentCheeTokenEnabled ? (
              <div className="content-item flex-column">
                <div className="coin-info flex align-center just-end">
                  Wallet Balance: {selectedRow.cheeBalance} {name}
                </div>
              </div>
            ) : (
              <div className="content-item" style={{ marginBottom: 0 }}>
                <div
                  className="btn"
                  style={{
                    color: '#FFFFFF',
                  }}
                  onClick={() => {
                    onApprove(0);
                  }}
                >
                  Enable {name}
                </div>
              </div>
            )}

            <div
              className="line"
              style={{
                backgroundColor: ui.theme === 'dark' ? '#2F413B' : '#000000',
                opacity: ui.theme === 'dark' ? 1 : 0.1,
              }}
            />
            <div className="content-item just-between second">
              <div className="coin-type" style={{ color: ui.theme === 'dark' ? '#FFFFFF' : '#000000' }}>
                <img src={selectedRow.img} alt="" />
                {veTokenName}
              </div>
              <span className="coin-price">{formatNumber(selectedRow.veTokenNum, true, 5)}</span>
            </div>
            {currentVeTokenEnabled ? (
              <div className="content-item flex-column">
                <div className="coin-info flex align-center just-end">
                  Wallet Balance: {formatNumber(selectedRow.veTokenBalance, true, 5)} {veTokenName}{' '}
                  {selectedRow.veTokenBalance.lt(selectedRow.veTokenNum) && (
                    <span className="error-msg">(Insufficient balance)</span>
                  )}
                </div>
              </div>
            ) : (
              <div className="content-item">
                <div
                  className="btn"
                  style={{
                    color: '#FFFFFF',
                  }}
                  onClick={() => {
                    onApprove(1);
                  }}
                >
                  Enable {veTokenName}
                </div>
              </div>
            )}

            <div className="content-item mint-price-content flex-column">
              <div
                className="label"
                style={{
                  color: ui.theme === 'dark' ? '#ffffff' : '#000000',
                }}
              >
                {vename} to receive
              </div>
              <div
                className="mint-price-items"
                style={{
                  backgroundColor: ui.theme === 'dark' ? '#101C18' : '#F6F6F6',
                }}
              >
                <div className="coin-info">
                  <div>
                    <span>ID: </span>
                    {selectedRow.ID}
                  </div>
                  <div>
                    <span>Balance: </span> {selectedRow.Balance}
                  </div>
                  <div>
                    <span>Locked Until: </span>
                    {selectedRow.date}
                  </div>
                </div>
              </div>
            </div>
            {currentVeTokenEnabled && currentCheeTokenEnabled && (
              <Button
                className={redeemEnableProgressShow ? 'content-item btn' : 'content-item btn btn-hover'}
                onClick={() => {
                  onRedeem();
                }}
                style={{
                  color: '#FFFFFF',
                  border: 'none',
                }}
                disabled={!!redeemEnableProgressShow}
              >
                Redeem
              </Button>
            )}
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}

RedeemModal.propTypes = {
  visible: PropTypes.bool,
  wcUri: PropTypes.string,
  onCancel: PropTypes.func,
};

RedeemModal.defaultProps = {
  visible: false,
  wcUri: null,
  onCancel: () => {},
};

const mapStateToProps = ({ account, ui }) => ({
  settings: account.setting,
  ui,
});

const mapDispatchToProps = (dispatch) => {
  const { setSetting } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting,
    },
    dispatch
  );
};

export default compose(connectAccount(mapStateToProps, mapDispatchToProps))(RedeemModal);
